import AuthService from "@/services/auth.service"

export default {
    state: {
        user: {
            name: '',
            email: '',
            photo: '',
        },
        loggedIn: false,
    },
    mutations: {
        SET_USER_AUTHENTICATED (state, user) {
            state.user = user
            state.loggedIn = true
        },
        LOGOUT_USER (state) {
            state.user = {
                name: '',
                email: '',
                photo: '',
            }

            state.loggedIn = false
        }
    },
    actions : {
        auth ({dispatch}, params) {
            return AuthService.auth(params)
                                .then(() => dispatch('getMe'))
        },

        getMe ({commit}) {
            commit('CHANGE_LOADING', true)

            AuthService.getMe()
                        .then(user => commit('SET_USER_AUTHENTICATED', user))
                        .finally(() => commit('CHANGE_LOADING', false))
        },

        logout ({ commit }) {
            return AuthService.logout()
                                .then(() => commit('LOGOUT_USER'))
        }
    }
}