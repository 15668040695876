<template>
  <div class="comments" v-if="lesson.name != '-'">
    <div class="header">
      <span class="title" v-if="loading">Carregando as dúvidas da aula... aguarde!</span>
      <span class="title" v-else>Dúvidas (Total: {{ supports.length }})</span>
      <button class="btn primary" @click="openModal">
        <i class="fas fa-plus"></i> Enviar nova dúvida
      </button>
    </div>
    <supports-component/>

    <modal-support
      :show-modal="modal.open"
      :support-reply="modal.support"
      @closeModal="modal.open = false"
    ></modal-support>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

import SupportsComponent from '@/components/SupportsComponent.vue'
import ModalSupport from '@/components/ModalSupport.vue'

export default {
  name: 'Supports',
  setup() {
    const store = useStore()

    let loading = ref(false)

    const lesson = computed(() => store.state.courses.lessonPlayer)
    const supports = computed(() => store.state.supports.supports.data)

    watch(()=> store.state.courses.lessonPlayer, () => {
      loading.value = true
      store.dispatch('getSupportsLesson', lesson.value)
            .finally(() => loading.value = false)
    })

    let modal = ref({
      open: false,
      support: "",
    });
    const openModal = () => modal.value = {open: true, support: ''}

    return {
      loading,
      lesson,
      supports,
      modal,
      openModal,
    }
  },
  components: {
    SupportsComponent,
    ModalSupport,
  }
}
</script>