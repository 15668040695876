<template>
  <div>
    <section
      id="loginPage"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/bgLogin.jpg') + ')',
      }"
    >
      <div class="loginContent">
        <div class="loginCard">
          <div
            class="decor"
            style="background-image: url('./assets/images/building.jpg')"
          >
            <div class="content">
              <span class="logo"
                ><img
                  :src="[require('@/assets/images/logo.svg')]"
                  alt=""
                /> </span
              ><span class="dots"
                ><span></span> <span></span> <span></span>
              </span>
              <span class="description">
                <p>
                  Os melhores e mais completos cursos do Brasil, cursos com
                  projetos reais. Do zero ao profissional.
                </p>
              </span>
              <span class="copyright fontSmall"
                >Todos os Direitos reservados - <b>Especializati</b></span
              >
            </div>
          </div>
          <div class="login">
            <div class="content">
              <span class="logo"
                ><img :src="['./assets/images/logoDark.svg']" alt="" />
              </span>
              <span>
                <p>Seja muito bem vindo(a)!</p>
              </span>
              <span class="dots"
                ><span></span> <span></span> <span></span> </span
              ><span class="description"
                >Acesse nossa plataforma e desfrute de cursos completos para sua
                especialização.</span
              >
              <form action="#" method="POST" @submit.prevent="sign">
                <div class="groupForm">
                  <i class="far fa-envelope"></i>
                  <input
                    type="email"
                    v-model="email"
                    name="email"
                    placeholder="E-mail"
                    required
                  />
                </div>
                <div class="groupForm">
                  <i class="far fa-key"></i>
                  <input
                    :type="type"
                    v-model="password"
                    name="password"
                    placeholder="Senha"
                    required
                  />
                  <i class="far fa-eye buttom" @click="toogleViewPassword"></i>
                </div>
                <button
                  :class="[
                    'btn',
                    'primary',
                    loading || loadingStore ? 'disabled' : '',
                  ]"
                  type="submit"
                  :disabled="loading || loadingStore"
                >
                  <span v-if="loading">Acessando</span>
                  <span v-else-if="loadingStore">Verificando...</span>
                  <span v-else>Login</span>
                </button>
              </form>
              <span>
                <p class="fontSmall">
                  Esqueceu sua senha?
                  <router-link :to="{name: 'forgot.password'}" class="link primary">Clique aqui</router-link>
                </p>
              </span>
            </div>
            <span class="copyright fontSmall"
              >Todos os Direitos reservados - <b>Especializati</b></span
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "Sign",
  setup() {
    document.title = 'Login - Campus EAD EspecializaTi'

    const store = useStore();
    const email = ref("");
    const password = ref("");
    const loading = ref(false);
    const type = ref("password")

    const loadingStore = computed(() => store.state.loading);

    const toogleViewPassword = () => type.value = type.value == 'password' ? 'text' : 'password'

    watch(
      () => store.state.users.loggedIn,
      (loggedIn) => {
        if (loggedIn) {
          router.push({ name: "home" });
        }
      }
    );

    const sign = async () => {
      loading.value = true;

      const params = {
        email: email.value,
        password: password.value,
        device_name: "vue3-browser",
      };

      store
        .dispatch("auth", params)
        .then(() => router.push({ name: "home" }))
        .catch((error) => {
          let messageError = "Falha na requisição";
          if (error.status == 422) messageError = "Dados Inválidos";
          else if (error.status == 404) messageError = "Usuário Não Encontrado";

          notify({
            title: "Falha ao autenticar",
            text: messageError,
            type: "warn",
          });
        })
        .finally(() => (loading.value = false));
    };

    return {
      email,
      password,
      sign,
      loading,
      loadingStore,
      type,
      toogleViewPassword,
    };
  },
};
</script>
