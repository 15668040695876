<template>
  <div class="training">
    <div class="card bg-laravel">
      <span class="icon"
        ><img
          :src="[course.image != '' ? course.image : require('@/assets/images/icons/default.png')]"
          :alt="course.name"
        /> </span
      ><span class="title">{{ lesson.name }}</span>
      <router-link :to="{ name: 'home' }" class="btn laravel"><i class="fas fa-home"></i> Meus Cursos</router-link>
    </div>
    <iframe
      v-if="lesson.video"
      class="animate__animated animate__fadeIn"
      width="100%"
      height="auto"
      :src="lesson.video"
      :title="lesson.name"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>

  <div class="description-lesson" v-if="lesson.video">
    {{ lesson.description }}
  </div>
</template>

<script>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()

    const lesson = computed(() => store.state.courses.lessonPlayer)
    const course = computed(() => store.state.courses.courseSelected)
    
    watch(()=> store.state.courses.lessonPlayer, () => {
      // Mark this lesson as viewed
      if (lesson.value.identify != '')
        setTimeout(() => store.dispatch('markLessonViewed'), 30000)
    })

    return {
      lesson,
      course,
    }
  }
}
</script>