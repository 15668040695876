import BaseService from "./base.service"

export default class CourseService extends BaseService {

  static async getMyCourses() {
    return new Promise((resolve, reject) => {
      this.request({ auth: true })
        .get('/my-courses')
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response))
    })
  }

  static async getTrainingsOfCourse(course) {
    return new Promise((resolve, reject) => {
      this.request({ auth: true })
        .get(`/courses/${course.identify}/trainings`)
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response))
    })
  }

  static async getModulesAndLessonsOfTraining(training) {
    return new Promise((resolve, reject) => {
      this.request({ auth: true })
        .get(`/trainings/${training.identify}/modules`)
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response))
    })
  }

  static async getLesson(identify) {
    return new Promise((resolve, reject) => {
      this.request({ auth: true })
        .get(`/lessons/${identify}`)
        .then(response => resolve(response.data.data))
        .catch(error => reject(error.response))
    })
  }

  static async markLessonViewed(lesson) {
    return new Promise((resolve, reject) => {
      this.request({ auth: true })
        .post(`/lessons/${lesson.identify}/viewed`)
        .then(() => resolve())
        .catch(error => reject(error.response))
    })
  }

}