<template>
  <div>
    <div class="pageTitle">
      <span class="title" v-if="loading">Carregando os Treinamentos do {{ course.name }}... Aguarde!</span>
      <span class="title" v-else>Treinamentos do {{ course.name }}</span>
      <router-link :to="{ name: 'home' }" class="btn primary" v-if="!loading"><i class="fas fa-chevron-left"></i> Voltar</router-link>
      <span class="dots"><span></span> <span></span> <span></span></span>
    </div>
    <div class="content">
      <ul class="listCourses" v-if="!loading">
        <card-course
          class="animate__animated animate__headShake"
          v-for="training in trainings"
          :key="training.identify"
          :title="training.name"
          :image="course.image"
          :bg-name="'vue'"
          :resource="training"
          @buttonClicked="trainingSelected"
        ></card-course>
      </ul>
    </div>
  </div>
</template>

<script>
import CardCourse from "@/components/CardCourse.vue"
import { computed } from "vue"
import { useStore } from "vuex"
import router from "@/router"

export default {
  name: "TrainingsOfCourse",
  setup() {
    const store = useStore()
    
    const loading = computed(() => store.state.loading)
    const course = computed(() => store.state.courses.courseSelected)
    const trainings = computed(() => store.state.courses.courseSelected.trainings)

    const trainingSelected = (training) => {
      router.push({name: 'modules'})

      store.dispatch('getModulesAndLessonsOfTraining', training)
    }

    if (course.value.name === undefined) router.push({name: 'home'})

    return {
      course,
      loading,
      trainings,
      trainingSelected,
    };
  },
  components: {
    CardCourse,
  },
};
</script>