<template>
  <li>
    <span class="card"
        :class="['bg-' + bgName]"
      ><span class="icon"><img :src="[image != '' ? image : require('@/assets/images/icons/default.png')]" :alt="title" /> </span
      ><span class="title">{{ title }}</span>
      <span class="action">
        <!-- <router-link :to="{name: routeName}" :class="['btn', bgName]">Acessar</router-link> -->
        <a href="#" :class="['btn', bgName]" @click.prevent="$emit('button-clicked', resource)">Acessar</a>
      </span></span><span class="dots"><span></span> <span></span> <span></span></span>
  </li>
</template>

<script>
export default {
    props: ['title', 'image', 'bgName', 'resource'],
    emits: ['button-clicked'],
}
</script>