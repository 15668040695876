import axios from "axios"

import { API_URL, TOKEN_NAME } from "@/configs"

export class Http {
    constructor (status) {
        const token = localStorage.getItem(TOKEN_NAME)
        
        const headers = status.auth ? {
            Authorization: "Bearer " + token
        } : {}

        this.instance = axios.create({
            baseURL: API_URL,
            headers
        })

        return this.instance
    }
}