<template>
  <div>
    <div class="pageTitle">
      <span class="title" v-if="loading"
        >Carregando os seus cursos... (aguarde)!</span
      >
      <span class="title" v-else>Meus Cursos ({{ myCourses.length }})</span>
      <span class="dots"><span></span> <span></span> <span></span></span>
    </div>
    <div class="content">
      <ul class="listCourses" v-if="!loading">
        <card-course
          class="animate__animated animate__headShake"
          v-for="course in myCourses"
          :key="course.identify"
          :title="course.name"
          :image="course.image"
          :bg-name="'laravel'"
          :resource="course"
          @buttonClicked="courseSelected"
        ></card-course>
      </ul>
    </div>
  </div>
</template>

<script>
import CardCourse from "@/components/CardCourse.vue"
import { onMounted, computed } from "vue"
import { useStore } from "vuex"
import router from "@/router"

export default {
  name: "MyCourses",
  setup() {
    const store = useStore()
    const loading = computed(() => store.state.loading)
    const myCourses = computed(() => store.state.courses.myCourses)

    onMounted(() => store.dispatch('getMyCourses'))

    const courseSelected = (course) => {
      router.push({name: 'trainings'})

      store.dispatch('getTrainingsOfCourse', course)
    }

    return {
      myCourses,
      loading,
      courseSelected,
    };
  },
  components: {
    CardCourse,
  },
};
</script>