import CourseService from '@/services/course.services'

export default {
    state: {
        myCourses: [],
        courseSelected: {
            trainings: []
        },
        modulesOfTraining: [],
        lessonPlayer: {
            'identify': '',
            'name': '-',
            'description': '',
            'video': 'https://www.youtube.com/embed/dhG8MoQ9sRU',
            'date': '',
            'total_views': 0,
        },
    },
    mutations: {
        SET_COURSES (state, courses) {
            state.myCourses = courses
        },
        SET_COURSE_SELECTED (state, course) {
            state.courseSelected = course
        },
        SET_TRAININGS_COURSE_SELECTED (state, trainings) {
            state.courseSelected.trainings = trainings
        },
        SET_MODULES_AND_LESSONS (state, modules)  {
            state.modulesOfTraining = modules
        },
        SET_LESSON_PLAYER (state, lesson)  {
            state.lessonPlayer = lesson
        },
        MARK_LESSON_VIEWED (state, lessonViewed) {
            let modules = state.modulesOfTraining
            modules.forEach((module, indexModule) => {
                module.lessons.forEach((lesson, indexLesson) => {
                    if (lesson.identify == lessonViewed.identify)
                        modules[indexModule].lessons[indexLesson].total_views = 1;
                })
            })

            state.modulesOfTraining = modules
        },
    },
    actions: {
        getMyCourses ({commit, state}) {
            if (state.myCourses.length != 0) return
            commit('CHANGE_LOADING', true)
            commit('SET_COURSES', []) // reset

            return CourseService
                        .getMyCourses()
                        .then(data => commit('SET_COURSES', data))
                        .finally(() => commit('CHANGE_LOADING', false))
        },

        getTrainingsOfCourse ({commit}, course) {
            commit('CHANGE_LOADING', true)
            commit('SET_COURSE_SELECTED', course)

            return CourseService
                        .getTrainingsOfCourse(course)
                        .then(training => commit('SET_TRAININGS_COURSE_SELECTED', training))
                        .finally(() => commit('CHANGE_LOADING', false))
        },

        getModulesAndLessonsOfTraining ({commit}, training) {
            commit('SET_MODULES_AND_LESSONS', [])
            commit('SET_SUPPORTS', []) // reset supports
            commit('SET_LESSON_PLAYER', {name: '-'}) // reset lessons
            commit('CHANGE_LOADING', true)

            return CourseService
                        .getModulesAndLessonsOfTraining(training)
                        .then(modules => commit('SET_MODULES_AND_LESSONS', modules))
                        .finally(() => commit('CHANGE_LOADING', false))
        },

        getLesson ({commit}, identify) {
            commit('CHANGE_LOADING', true)

            return CourseService
                        .getLesson(identify)
                        .then(lesson => commit('SET_LESSON_PLAYER', lesson))
                        .finally(() => commit('CHANGE_LOADING', false))
        },

        markLessonViewed ({commit, state}) {
            commit('CHANGE_LOADING', true)

            return CourseService
                        .markLessonViewed(state.lessonPlayer)
                        .then(() => commit('MARK_LESSON_VIEWED', state.lessonPlayer))
                        .finally(() => commit('CHANGE_LOADING', false))
        },
    },
}