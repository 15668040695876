import BaseService from "./base.service"

export default class PasswordResetService extends BaseService {

    static async sendLinkResetPassword (params) {
        return new Promise((resolve, reject) => {
            this.request()
                    .post('/forgot-password', params)
                    .then(() => resolve('ok'))
                    .catch((error) => reject(error.response))

        })
    }

    static async resetPassword (params) {
        return new Promise((resolve, reject) => {
            this.request()
                .post('/reset-password', params)
                .then(() => resolve('ok'))
                .catch((error) => reject(error.response))
        })
    }

}
