import { createRouter, createWebHistory } from 'vue-router'
import Auth from '@/views/auth/Sign.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import Home from '@/views/home/Home.vue'
import Training from '@/views/trainings/Training.vue'
import MySupports from '@/views/supports/MySupports.vue'
import ModulesAndLessons from '@/views/modules-and-lessons/ModulesAndLessons.vue'

import { TOKEN_NAME } from "@/configs"
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'auth',
    component: Auth
  }, {
    path: '/recuperar-senha',
    name: 'forgot.password',
    component: ForgotPassword
  }, {
    path: '/reset/:token',
    name: 'reset.password',
    component: ResetPassword,
    props: true,
  }, {
    path: '/campus',
    component: () => import('@/layouts/Default.vue'),
    children: [
      {path: 'minhas-duvidas', name: 'my.supports', component: MySupports},
      {path: 'modulos', name: 'modules', component: ModulesAndLessons},
      {path: 'treinamentos', name: 'trainings', component: Training},
      {path: '', name: 'home', component: Home},
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, _, next) => {
  const loggedIn = store.state.users.loggedIn
  if (to.name != 'reset.password' && !loggedIn) {
    const token = await localStorage.getItem(TOKEN_NAME)
    if (!token && to.name != 'auth' && to.name != 'forgot.password') {
      return router.push({name: 'auth'})
    }

    await store.dispatch('getMe')
                .catch(() => {
                  if (to.name != 'auth') return router.push({name: 'auth'})
                })
  }

  next()
})

export default router
