<template>
  <div class="content">
    <div class="card" v-for="support in supports.data" :key="support.identify">
      <div class="commentContent main animate__animated animate__fadeIn">
        <span class="avatar"
          ><img
            :src="[
              support.user.image != ''
                ? support.user.image
                : require('@/assets/images/avatars/user-no-photo.png'),
            ]"
            :alt="support.user.name"
        /></span>
        <div class="comment">
          <div class="balloon">
            <span class="fas fa-sort-down"></span>
            <span class="owner"
              >{{ support.user.name }} - {{ support.date_br }} (Número:
              {{ support.identify }}) ({{ support.status_label }})</span
            >
            <span class="text">
              <pre>{{ support.description }}</pre>
            </span>
          </div>
        </div>
        <button
          class="btn primary"
          @click.prevent="toogleRepliesSupport(support.identify)"
        >
          <span v-if="showSupport == support.identify">Ocultar Respostas</span>
          <span v-else>Exibir Respostas ({{ support.replies.length }})</span>
        </button>
      </div>
      <div class="answersContent" v-show="showSupport == support.identify">
        <div
          class="animate__animated animate__fadeIn"
          v-for="reply in support.replies"
          :key="reply.identify"
          :class="[
            'commentContent',
            reply.user.name != support.user.name ? 'rightContent' : '',
          ]"
        >
          <span class="avatar" v-if="reply.user.name === support.user.name"
            ><img
              :src="[
                reply.user.image != ''
                  ? reply.user.image
                  : require('@/assets/images/avatars/user-no-photo.png'),
              ]"
              :alt="reply.user.name"
          /></span>
          <div class="comment">
            <div class="balloon animate__animated animate__headShake">
              <span class="fas fa-sort-down"></span>
              <span class="owner"
                >{{ reply.user.name }} - {{ reply.date_br }}</span
              >
              <span class="text"><pre v-html="reply.description"></pre></span>
            </div>
          </div>
          <span class="avatar" v-if="reply.user.name != support.user.name"
            ><img
              :src="[
                reply.user.image != ''
                  ? reply.user.image
                  : require('@/assets/images/avatars/user-no-photo.png'),
              ]"
              :alt="reply.user.name"
          /></span>
        </div>

        <span class="answer"
          ><button
            class="btn primary"
            @click.prevent="openModal(support.identify)"
          >
            Responder
          </button></span
        >
      </div>
    </div>
  </div>

  <modal-support
    :show-modal="modal.open"
    :support-reply="modal.support"
    @closeModal="modal.open = false"
  ></modal-support>
</template>

<script>
import { computed, ref } from "vue"

import ModalSupport from "./ModalSupport.vue";
import { useStore } from "vuex";

export default {
  name: "SupportsComponent",
  setup() {
    const store = useStore();
    const supports = computed(() => store.state.supports.supports)

    let showSupport = ref(0);
    let modal = ref({
      open: false,
      support: "",
    });

    const toogleRepliesSupport = (supportId) => {
      if (showSupport.value == supportId) showSupport.value = 0;
      else showSupport.value = supportId;
    };

    const openModal = (reply) => (modal.value = { open: true, support: reply });

    return {
      supports,
      showSupport,
      toogleRepliesSupport,
      openModal,
      modal,
    };
  },
  components: {
    ModalSupport,
  },
};
</script>