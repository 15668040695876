<template>
  <div>
    <section
      id="loginPage"
      :style="{
        backgroundImage: 'url(' + require('@/assets/images/bgLogin.jpg') + ')',
      }"
    >
      <div class="loginContent">
        <div class="loginCard">
          <div
            class="decor"
            style="background-image: url('./assets/images/building.jpg')"
          >
            <div class="content">
              <span class="logo"
                ><img
                  :src="[require('@/assets/images/logo.svg')]"
                  alt=""
                /> </span
              ><span class="dots"
                ><span></span> <span></span> <span></span>
              </span>
              <span class="description">
                <p>
                  Os melhores e mais completos cursos do Brasil, cursos com
                  projetos reais. Do zero ao profissional.
                </p>
              </span>
              <span class="copyright fontSmall"
                >Todos os Direitos reservados - <b>Especializati</b></span
              >
            </div>
          </div>
          <div class="login">
            <div class="content">
              <span class="logo"
                ><img :src="['./assets/images/logoDark.svg']" alt="" />
              </span>
              <span>
                <p>Seja muito bem vindo(a)!</p>
              </span>
              <span class="dots"
                ><span></span> <span></span> <span></span> </span
              ><span class="description"
                >Acesse nossa plataforma e desfrute de cursos completos para sua
                especialização.</span
              >
              <form action="#" method="POST" @submit.prevent="sendLinkResetPassword">
                <div class="groupForm">
                  <i class="far fa-envelope"></i>
                  <input
                    type="email"
                    v-model="email"
                    name="email"
                    placeholder="E-mail"
                    required
                  />
                </div>
                <button
                  :class="[
                    'btn',
                    'primary',
                    loading ? 'disabled' : '',
                  ]"
                  type="submit"
                  :disabled="loading"
                >
                  <span v-if="loading">Enviando...</span>
                  <span v-else>Recuperar Senha</span>
                </button>
              </form>
              <span>
                <p class="fontSmall">
                  Entrar?
                  <router-link :to="{name: 'auth'}" class="link primary">Clique aqui</router-link>
                </p>
              </span>
            </div>
            <span class="copyright fontSmall"
              >Todos os Direitos reservados - <b>Especializati</b></span
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from "vue";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router";

import PasswordResetService from "@/services/password.reset.service"

export default {
  name: "forgot-password",
  setup() {
    document.title = 'Recuperar - Campus EAD EspecializaTi'

    const email = ref("")
    const loading = ref(false)

    const sendLinkResetPassword = async () => {
      loading.value = true;

      const params = {
        email: email.value,
      };

      PasswordResetService
        .sendLinkResetPassword(params)
        .then(() => {
          notify({
            title: 'Enviado com Sucesso',
            text: 'Confira em sua caixa de entrada, ou na caixa de SPANs',
          });

          email.value = ''

          router.push({ name: "auth" })
        })
        .catch((error) => {
          let messageError = "Falha na requisição";
          if (error.status == 422) messageError = "Dados Inválidos";
          else if (error.status == 404) messageError = "Usuário Não Encontrado";

          notify({
            title: "Falha ao enviar, tente novamente mais tarde",
            text: messageError,
            type: "warn",
          });
        })
        .finally(() => (loading.value = false));
    };

    return {
      email,
      loading,
      sendLinkResetPassword,
    };
  },
};
</script>
