import BaseService from "./base.service"

export default class SupportService extends BaseService {

    static async getSupportsLesson(lesson)  {
        return new Promise((resolve, reject) => {
            this.request({auth: true})
                .get(`/lessons/${lesson.identify}/supports`,)
                .then(response => resolve(response.data))
                .catch(error => reject(error.response))
        })
    }

    static async createSupport (params)  {
        return new Promise((resolve, reject) => {
            this.request({auth: true})
                .post('/supports', params)
                .then(response => resolve(response.data.data))
                .catch(error => reject(error.response))
        })
    }

    static async createReplySupport (params)  {
        return new Promise((resolve, reject) => {
            this.request({auth: true})
                .post('/reply-supports', params)
                .then(response => resolve(response.data.data))
                .catch(error => reject(error.response))
        })
    }

    static async mySupports (params)  {
        return new Promise((resolve, reject) => {
            this.request({auth: true})
                .get('/my-supports', {params})
                .then(response => resolve(response.data))
                .catch(error => reject(error.response))
        })
    }
}