<template>
  <div class="pageTitle">
    <span class="title">
      Minhas Dúvidas
      <span v-if="loading">( Carregando... )</span>
      <span v-else
        >( {{ mySupports.data.length }}/{{ mySupports.meta.total }} )</span
      >
    </span>
    <span class="dots"><span></span> <span></span> <span></span></span>
  </div>
  <section class="container">
    <div class="left">
      <div class="card">
        <div class="title bg-laravel">
          <span class="text">Filtros</span>
        </div>
        <div class="modules">
          <ul class="classes">
            <li :class="{active : status == ''}" @click="getMySupportsByStatus('')">Todos</li>
            <li :class="{active : status == 'A'}" @click="getMySupportsByStatus('A')">Aguardando Minha Resposta</li>
            <li :class="{active : status == 'P'}" @click="getMySupportsByStatus('P')">Aguardando Professor</li>
            <li :class="{active : status == 'C'}" @click="getMySupportsByStatus('C')">Finalizados</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="right" :style="{ display: 'grid' }">
      <div class="content">
        <div class="comments">
          <supports-component />
        </div>
      </div>

      <pagination :pagination="mySupports" @changePage="changePage" />
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

import SupportsComponent from "@/components/SupportsComponent.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "MySupports",

  setup() {
    const store = useStore();

    onMounted(() => store.dispatch("getMySupports"));

    const mySupports = computed(() => store.state.supports.supports);
    const loading = computed(() => store.state.loading);

    const status = ref("")
    const getMySupportsByStatus = (newStatus) => {
      status.value = newStatus
      store.dispatch("getMySupports", {status: newStatus})
    }

    const changePage = (page) => store.dispatch("getMySupports", {page})

    return {
      mySupports,
      loading,
      status,
      getMySupportsByStatus,
      changePage,
    };
  },
  components: {
    SupportsComponent,
    Pagination,
  },
};
</script>