import SupportService from "@/services/support.service"

export default {
    state: {
        supports: {
            data: [],
            meta: {
                total: 0
            }
        }
    },
    mutations: {
        SET_SUPPORTS (state, supports) {
            state.supports = Object.assign({}, state.supports, supports)
        },
        RESET_SUPPORTS (state) {
            state.supports = {
                data: [],
                meta: {
                    total: 0,
                    last_page: 0,
                }
            }
        },
        ADD_SUPPORT (state, support) {
            state.supports.data.unshift(support)
        },
        UPDATE_SUPPORT (state, supportUpdated) {
            let supports = state.supports.data
            supports.forEach((support, index) => {
                if (support.identify == supportUpdated.identify)
                    support[index] = supportUpdated;
            })

            state.supports.data = supports
        },
        REMOVE_SUPPORT (state, supportDeleted) {
            let supports = state.supports.data
            supports.forEach((support, index) => {
                if (support.identify == supportDeleted.identify)
                    supports.splice(index, 1);
            })

            state.supports.data = supports
        },
        ADD_REPLY_SUPPORT (state, reply) {
            let supports = state.supports.data
            supports.forEach((support, index) => {
                if (support.identify == reply.support)
                    supports[index].replies.push(reply)
            })

            state.supports.data = supports
        }
    },
    actions : {
        getSupportsLesson ({commit}, lesson) {
            commit('RESET_SUPPORTS')
            commit('CHANGE_LOADING', true)

            return SupportService
                        .getSupportsLesson(lesson)
                        .then(supports => commit('SET_SUPPORTS', supports))
                        .finally(() => commit('CHANGE_LOADING', false))
        },
        createSupport ({commit}, params) {
            commit('CHANGE_LOADING', true)

            return SupportService
                        .createSupport(params)
                        .then(support => commit('ADD_SUPPORT', support))
                        .finally(() => commit('CHANGE_LOADING', false))
        },
        createReplySupport ({commit}, params) {
            commit('CHANGE_LOADING', true)

            return SupportService
                        .createReplySupport(params)
                        .then(reply => commit('ADD_REPLY_SUPPORT', {support: params.support, ...reply}))
                        .finally(() => commit('CHANGE_LOADING', false))
        },
        getMySupports ({commit}, params) {
            commit('RESET_SUPPORTS') // reset
            commit('CHANGE_LOADING', true)

            return SupportService
                        .mySupports(params)
                        .then(supports => commit('SET_SUPPORTS', supports))
                        .finally(() => commit('CHANGE_LOADING', false))
        },
    }
}