<template>
  <div class="left">
    <div class="card">
      <div class="title bg-laravel">
        <span class="text" v-if="loading">Carregando... aguarde!</span>
        <span class="text" v-else>Modulos</span>
        <span class="icon far fa-stream"></span>
      </div>
      <div class="modules" v-for="module in modules" :key="module.identify" :class="{active: module.identify == showModule}">
        <div class="name" @click.prevent="toogleModuleView(module.identify)">
          <span class="text">{{ module.name }}</span>
          <span class="icon fas fa-sort-down"></span>
        </div>
        <ul class="classes" v-show="module.identify == showModule">
          <li
            class="animate__animated animate__fadeIn"
            v-for="lesson in module.lessons"
            :key="lesson.identify"
            :class="{active: lessonSelected.identify == lesson.identify}">
            <span class="check fas fa-check" :class="{active: lesson.total_views > 0}"></span>
            <span class="nameLesson" @click="playLesson(lesson)">{{ lesson.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue"
import { useStore } from 'vuex'

export default {
  setup() {
    let showModule = ref(0)

    const store = useStore()
    const loading = computed(() => store.state.loading)

    const modules = computed(() => store.state.courses.modulesOfTraining)

    const lessonSelected = computed(() => store.state.courses.lessonPlayer)
    const playLesson = (lesson) => store.commit('SET_LESSON_PLAYER', lesson)

    const toogleModuleView = (moduleId) => {
      if (showModule.value == moduleId) showModule.value = 0;
      else showModule.value = moduleId;
    }

    return {
      showModule,
      toogleModuleView,
      loading,
      playLesson,
      modules,
      lessonSelected,
    };
  },
};
</script>