<template>
  <div>
    <div class="pageTitle">
      <span class="title">{{ course.name }}</span>
      <span class="dots"><span></span> <span></span> <span></span></span>
    </div>
    <div class="content">
      <div class="container">
        <modules/>
        <div class="right">
          <div class="content">
            <player></player>

            <supports></supports>

          </div>
        </div><!--right-->
      </div><!--container-->
    </div>
  </div>
</template>

<script>
import Modules from './components/modules.vue'
import Supports from './components/supports/supports.vue'
import Player from './components/player.vue'
import { useStore } from 'vuex'
import { computed } from "vue"

import router from '@/router'

export default {
  name: "ModulesAndLessons",
  setup() {
    const store = useStore()
  
    const course = computed(() => store.state.courses.courseSelected)

    if (course.value.name === undefined) router.push({name: 'home'})

    return {
      course,
    };
  },
  components: {
    Modules,
    Supports,
    Player,
  }
};
</script>