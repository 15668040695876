import { createStore } from 'vuex'
import courses from './modules/courses'
import supports from './modules/supports'
import users from './modules/users'

export default createStore({
  modules: {
    users,
    courses,
    supports,
  },
  state: {
    loading: false,
    loadingMsg: 'Carregando... aguarde!',
  },
  mutations: {
    CHANGE_LOADING (state, status, msg = 'Carregando... aguarde!') {
      state.loading = status
      state.loadingMsg = msg
    }
  },
  actions: {
  },
})
